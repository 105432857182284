import { React } from "react";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import AgreementComponent from "./agreement";
import { getMsaInfo } from "./../../../config/api_calls";
import HelperClass from "./../../../config/helperClass";
import { REPLACECONTENT } from "./../../../config/utility";

const auth = new HelperClass();

class MSA extends Component {
  constructor() {
    super();
    this.state = {
      Agreement_Content: "",
      title_agreement: "",
      bgColor: "#f5f6fa",
      is_loading: true,
      vendor_signor_signature_file: "",
      published_date: "",
    };
  }

  // agreementSigned = () => {
  //   this.setState({ is_nda_signed: true });
  // };

  async componentDidMount() {
    const gettingMsa = await getMsaInfo(auth.getAccount(), auth.getToken());

    if (
      gettingMsa.data.status === 403 ||
      gettingMsa.data.errors === "authentication missing" ||
      gettingMsa.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (gettingMsa.data.status === 404) {
      window.location.replace("/error");
    } else if (
      gettingMsa.data.status === 200 &&
      gettingMsa.data.message === "success"
    ) {
      let objReplace = {
        resellerCompName: auth.getCompName(),
        reseller_name: gettingMsa.data.data[0]["name"],
        reseller_comp_name: gettingMsa.data.data[0]["comp_name"],
        reseller_email: gettingMsa.data.data[0]["email"],
        reseller_phone_no: gettingMsa.data.data[0]["phone"],
        vendor_signor_name: gettingMsa.data.agreement[0].vendor_signor_name,
        vendor_signor_comp_name:
          gettingMsa.data.agreement[0].vendor_signor_comp_name,
        vendor_signor_title: gettingMsa.data.agreement[0].vendor_signor_title,
        vendor_signor_address:
          gettingMsa.data.agreement[0].vendor_signor_address,
        vendor_signor_email: gettingMsa.data.agreement[0].vendor_signor_email,
        vendor_signor_phone_no:
          gettingMsa.data.agreement[0].vendor_signor_phone_no,
        vendor_signor_fax: gettingMsa.data.agreement[0].vendor_signor_fax,
      };

      let newContent = REPLACECONTENT(
        gettingMsa.data.agreement[0]["agreement_content"],
        objReplace,
        ""
      );

      newContent = newContent.replace(
        "VENDOR SIGNATURE DATE SHOWN HERE",
        gettingMsa.data.agreement[0]["published_date"]
      );

      this.setState({
        published_date: gettingMsa.data.agreement[0]["published_date"],
        title_agreement: gettingMsa.data.agreement[0]["agreement_title"],
        bgColor: "white",
        is_loading: false,
        Agreement_Content: newContent.replace(
          /\[\[IMG_VENDOR_SIGN\]\]/g,
          process.env.REACT_APP_VENDOR_PORTAL_PATH +
            gettingMsa.data.agreement[0]["vendor_signor_signature_file"]
        ),
        vendor_signor_signature_file:
          gettingMsa.data.agreement[0]["vendor_signor_signature_file"],
      });
    } else {
      window.location.replace("/error");
    }
  }

  render() {
    if (this.props.is_msa_signed === "Yes") {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <div className="nk-content ">
          <div className="container-fluid">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                {this.state.is_loading === true ? (
                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "20%" }}
                  >
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <AgreementComponent
                    content={this.state.Agreement_Content}
                    title={this.state.title_agreement}
                    backgroundColor={this.state.bgColor}
                    vendor_signor_signature_file={
                      this.state.vendor_signor_signature_file
                    }
                    published_date={this.state.published_date}
                    mainHistory={this.props}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MSA);
