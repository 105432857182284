import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listServices,
  serviceRatesUpdate,
  serviceDefaultUpdate,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";

const auth = new HelperClass();

class Services extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      nrc_rate_type: "Flat",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      sms_in: "",
      sms_out: "",
      columnServices: [
        { name: "Id" },
        { name: "Title" },
        { name: "Name" },
        { name: "Nrc($)" },
        { name: "Mrc($)" },
        { name: "Service Type" },
        { name: "Rate($)" },
        { name: "SMS In($)" },
        { name: "SMS Out($)" },
        {
          name: "Details",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  {" "}
                  <a
                    href="#"
                    onClick={() => this.showDetails(value, tableMeta)}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    {this.viewBtns()}
                  </a>
                </div>
              );
            },
          },
        },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <a
                    href="#"
                    onClick={() => this.setMarkUp(value, tableMeta)}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    <CreateIcon
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </a>{" "}
                </div>
              );
            },
          },
        },
        {
          name: "Default",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
      ],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[9];
    if (is_default === "No") {
      return (
        <a
          href="#"
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          Set Default
        </a>
      );
    } else {
      return "Yes";
    }
  };

  async componentDidMount() {
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("this.props.history: ", this.props);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      window.location.replace("/error");
    }
  }

  setMarkUp = async (value, tableMeta) => {
    await this.handleStatesDynamic(
      tableMeta.rowData[0],
      tableMeta.rowData[3].replace("$", ""),
      tableMeta.rowData[4].replace("$", ""),
      tableMeta.rowData[6].replace("$", ""),
      tableMeta.rowData[1],
      tableMeta.rowData[5],
      tableMeta.rowData[7],
      tableMeta.rowData[8]
    );
    console.log("setMarkupData: ", this.state.service_title);
    window.$("#modalForm").modal("show");
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit,
    sms_in,
    sms_out
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
      sms_in: sms_in,
      sms_out: sms_out,
    });
  };

  showDetails = (value, tableMeta) => {
    this.setState({
      product_desc: tableMeta.rowData[7],
    });
    window.$("#modalFormDetail").modal("show");
  };

  setDefaultService = async (value, tableMeta) => {
    let id = tableMeta.rowData[0];
    let service_id = tableMeta.rowData[8];
    let service_type = tableMeta.rowData[5];
    let nrc = tableMeta.rowData[3];
    let mrc = tableMeta.rowData[4];
    let rate = tableMeta.rowData[6];

    this.setState({ errorMessage: "" });

    this.setState({
      errorMessage: "",
      waitMessage: "Please wait while we are making this service to default.",
      disabled: true,
      successMessage: "",
    });
    window.$("#modalFormDefault").modal("show");

    const saveResponce = await serviceDefaultUpdate(
      auth.getAccount(),
      auth.getToken(),
      id,
      service_id,
      service_type,
      nrc,
      mrc,
      rate
    );
    console.log("saveResponce.data: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        waitMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Service has been changed to default successfully.",
        disabled: false,
        waitMessage: "",
      });

      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDefault").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
        waitMessage: "",
      });
      //window.location.replace("/error");
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formSave = async () => {
    let {
      update_nrc,
      update_mrc,
      service_title,
      service_type_edit,
      update_rate,
      sms_in,
      sms_out,
    } = this.state;
    var is_validated = false;
    if (service_title === "") {
      is_validated = true;
    } else if (service_type_edit === "sms") {
      if (sms_in === "") {
        is_validated = true;
      }
      if (sms_out === "") {
        is_validated = true;
      }
    } else if (service_type_edit === "term") {
      if (update_rate === "") {
        is_validated = true;
      }
    } else if (service_type_edit === "did") {
      if (update_nrc === "") {
        is_validated = true;
      }
      if (update_mrc === "") {
        is_validated = true;
      }
      if (update_rate === "") {
        is_validated = true;
      }
    } else if (service_type_edit === "tfn") {
      if (update_nrc === "") {
        is_validated = true;
      }
      if (update_mrc === "") {
        is_validated = true;
      }
      if (update_rate === "") {
        is_validated = true;
      }
    }

    if (is_validated) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({ errorMessage: "" });

      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await serviceRatesUpdate(
        auth.getAccount(),
        auth.getToken(),
        update_nrc,
        update_mrc,
        update_rate,
        this.state.serviceId,
        service_title,
        service_type_edit
      );

      console.log("status: ", saveResponce.data.status);
      console.log("message: ", saveResponce.data.message);
      console.log("note: ", saveResponce.data.note);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Rates Saved Successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();

        setTimeout(function () {
          window.$("#modalForm").modal("hide");
        }, 3000);
        this.componentDidMount();
      } else {
        this.setState({
          errorMessage: saveResponce.data,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Services" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              href="/services/Addservice"
                              style={{
                                color: "#fff",
                              }}
                              className="btn btn-primary"
                            >
                              <em class="icon ni ni-plus-round"></em>
                              <span>Add New Service</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Services"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* DETAIL MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetail"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">
                              Service Description
                            </h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.product_desc}
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                href="#"
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHide();
                                }}
                              >
                                OK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAIL MODAL End */}
                  {/* DEFAULT MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDefault"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">Set Default</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.waitMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                      <div className="alert-text">
                                        <h4>Waiting...</h4>
                                        <p>{this.state.waitMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="nk-modal-action"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DEFAULT MODAL End */}
                  {/*  START MARKUP RATES MODAL*/}
                  <div className="modal fade zoom" tabIndex="-1" id="modalForm">
                    <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em class="icon ni ni-edit"></em> Edit Service
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.errorMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />
                          {/* NRC DIVS START */}

                          <div className="row">
                            <div className="col-sm-8">
                              <MutextField
                                id="service_title"
                                name="service_title"
                                label="Service Title *"
                                variant="outlined"
                                fullWidth
                                value={this.state.service_title}
                                onChange={this.handleChange}
                                type="text"
                              />
                            </div>
                            <div className="col-lg-4">
                              <MutextField
                                readonly
                                id="service_type_edit"
                                name="service_type_edit"
                                label="Service Type"
                                variant="outlined"
                                fullWidth
                                value={this.state.service_type_edit}
                                onChange={this.handleChange}
                                type="text"
                              />
                            </div>
                          </div>
                          {this.state.service_type_edit !== "sms" ? (
                            <>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                {this.state.service_type_edit !== "term" ? (
                                  <>
                                    <div className="col-sm-4">
                                      <MutextField
                                        id="update_mrc"
                                        name="update_mrc"
                                        label="MRC *"
                                        value={this.state.update_mrc}
                                        placeholder="MRC"
                                        onChange={this.handleChange}
                                        type="text"
                                        onKeyPress={() => {
                                          this.checkNumber();
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </div>
                                    <div className="col-sm-4">
                                      <MutextField
                                        id="update_nrc"
                                        name="update_nrc"
                                        label="NRC *"
                                        value={this.state.update_nrc}
                                        placeholder="NRC"
                                        onChange={this.handleChange}
                                        type="text"
                                        onKeyPress={() => {
                                          this.checkNumber();
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </div>
                                  </>
                                ) : null}
                                <div className="col-sm-4">
                                  <MutextField
                                    id="update_rate"
                                    name="update_rate"
                                    label="Per Minute Rate *"
                                    value={this.state.update_rate}
                                    onChange={this.handleChange}
                                    type="text"
                                    onKeyPress={() => {
                                      this.checkNumber();
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </>
                          ) : null}
                          {this.state.service_type_edit === "sms" ? (
                            <>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-6">
                                  <MutextField
                                    id="sms_in"
                                    name="sms_in"
                                    label="SMS Inbound Rate *"
                                    value={this.state.sms_in}
                                    placeholder="SMS Inbound Rate"
                                    onChange={this.handleChange}
                                    type="text"
                                    onKeyPress={() => {
                                      this.checkNumber();
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <MutextField
                                    id="sms_out"
                                    name="sms_out"
                                    label="SMS Outbound Rate *"
                                    value={this.state.sms_out}
                                    placeholder="SMS Outbound Rate"
                                    onChange={this.handleChange}
                                    type="text"
                                    onKeyPress={() => {
                                      this.checkNumber();
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </>
                          ) : null}
                          {/* MRC DIVS END */}
                          <br />
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-lg"
                              onClick={() => {
                                this.formSave();
                              }}
                              style={{
                                backgroundColor: THEME_COLOR,
                                color: THEME_TEXT_COLOR,
                                float: "right",
                              }}
                              disabled={this.state.disabled}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* MARKUP RATES MODAL */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
