import { Link } from "react-router-dom";
import { LOGO } from "./../../config/config";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import HelperClass from "./../../config/helperClass";

const auth = new HelperClass();

export default function AdminLeftSideBar(props) {
  function classCheck() {
    var menu_items = $(".has-sub");
    if (menu_items.hasClass("active")) {
      $(".has-sub").removeClass("active");
    } else {
      $(".has-sub").addClass("active");
    }
  }
  if (auth.isAdminLogin() === false) {
    auth.adminlogout();
    return <Redirect to="/admin_dashboard" />;
  }

  function menuRender() {
    var menu_items = props.menuArr;

    //console.log(menu_items);
  }

  return (
    <div className="nk-sidebar nk-sidebar-fixed" data-content="sidebarMenu">
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{
          backgroundColor: "white",
          height: "63.5px",
          borderRight: "1px solid #e8ecec",
        }}
      >
        <div className="nk-sidebar-brand">
          <div className="logo-link nk-sidebar-logo">
            <img className="logo-light logo-img" src={LOGO} alt="logo" />
            <img
              className="logo-dark logo-img"
              src={LOGO}
              alt="logo-dark"
              style={{ marginLeft: "0" }}
              onClick={() => menuRender()}
            />
          </div>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <div
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </div>
        </div>
      </div>
      <div
        className="nk-sidebar-element"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              {props.menuArr.map((data, i) =>
                `${data.submenu}` ? (
                  <li
                    className="nk-menu-item has-sub"
                    key={i}
                    onClick={() => classCheck()}
                  >
                    <div className="nk-menu-link nk-menu-toggle">
                      <span className="nk-menu-icon">
                        <em
                          className={data.tabimage}
                          style={{ color: "#202223" }}
                        ></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{
                          fontSize: "1.1rem",
                          color: "#202223",
                          marginLeft: "5%",
                          cursor: "pointer",
                        }}
                      >
                        {data.alt}
                      </span>
                    </div>
                    <ul className="nk-menu-sub">
                      {data.submenu.map((data, k) => (
                        <li className="nk-menu-item">
                          <Link to={"/" + data.link} className="nk-menu-link">
                            <span className="nk-menu-icon">
                              <em
                                className={data.tabimage}
                                style={{ color: "#202223" }}
                              ></em>
                            </span>
                            <span
                              className="nk-menu-text"
                              style={{ color: "#202223" }}
                            >
                              {data.alt}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li className="nk-menu-item" key={i}>
                    <Link className="nk-menu-link" to={"/" + data.link}>
                      <span className="nk-menu-icon">
                        <em
                          className={data.tabimage}
                          style={{ color: "#202223" }}
                        ></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ fontSize: "1.1rem", color: "#202223" }}
                      >
                        {data.alt}
                      </span>
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
