import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { browserHistory } from "react-router";
import Main from "./components/Reseller/main.js";

import AdminMain from "./components/Admin/admin";

import Test from "./Test.js";

import Error404 from "./components/Errors/404";
import Forgot from "./Pages/Reseller/Forgot/index";
import PasswordSetting from "./Pages/Reseller/PasswordSetting/index";
import SignUpVerification from "./Pages/Reseller/Verification/index";
import Login from "./Pages/Reseller/Login/index";

import Register from "./Pages/Reseller/Register/index";
import Addservice from "./Pages/Reseller/Services/addservice";

import AdminLogin from "./Pages/Admin/Login/admin_login";

function App() {
  console.log("version ", React.version);

  return (
    <div>
      <Router>
        <Switch>
          {/* COSTOMER RESELLER ROUTES */}
          <Route exact path="/" component={(props) => <Login {...props} />} />
          <Route
            exact
            path="/test"
            component={(props) => <Test {...props} />}
          />
          <Route
            exact
            path="/login"
            component={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            component={(props) => <Register {...props} />}
          />

          <Route
            exact
            path="/dashboard"
            component={(props) => <Main {...props} view="dashboard" />}
          />
          <Route
            exact
            path="/services"
            component={(props) => <Main {...props} view="services" />}
          />
          <Route
            exact
            path="/services/Addservice"
            component={(props) => <Main {...props} view="Addservice" />}
          />

          <Route
            exact
            path="/cms"
            component={(props) => <Main {...props} view="cms" />}
          />
          <Route
            exact
            path="/cms/Pageslist"
            component={(props) => <Main {...props} view="Pageslist" />}
          />
          <Route
            exact
            path="/cms/Addpages"
            component={(props) => <Main {...props} view="Addpages" />}
          />
          <Route
            exact
            path="/cms/Editpage/:id"
            component={(props) => <Main {...props} view="Editpage" />}
          />
          <Route
            exact
            path="/agreements/Pdfdownload/:id"
            component={(props) => <Main {...props} view="Pdfdownload" />}
          />
          <Route
            exact
            path="/cms/Menuslist"
            component={(props) => <Main {...props} view="Menuslist" />}
          />
          <Route
            exact
            path="/cms/Addmenus"
            component={(props) => <Main {...props} view="Addmenus" />}
          />
          <Route
            exact
            path="/cms/Editmenu/:id"
            component={(props) => <Main {...props} view="Editmenu" />}
          />
          <Route
            exact
            path="/cms/Headerlist"
            component={(props) => <Main {...props} view="Headerlist" />}
          />
          <Route
            exact
            path="/cms/Addheader"
            component={(props) => <Main {...props} view="Addheader" />}
          />
          <Route
            exact
            path="/cms/Websettings"
            component={(props) => <Main {...props} view="Websettings" />}
          />
          <Route
            exact
            path="/themes/Editor"
            component={(props) => <Main {...props} view="Editor" />}
          />
          <Route
            exact
            path="/themes"
            component={(props) => <Main {...props} view="themes" />}
          />
          <Route
            exact
            path="/domains"
            component={(props) => <Main {...props} view="domains" />}
          />
          <Route
            exact
            path="/domains/Adddomains"
            component={(props) => <Main {...props} view="Adddomains" />}
          />
          <Route
            exact
            path="/clients"
            component={(props) => <Main {...props} view="clients" />}
          />
          <Route
            exact
            path="/analytics"
            component={(props) => <Main {...props} view="analytics" />}
          />
          <Route
            exact
            path="/reports/:api_url/"
            component={(props) => <Main {...props} view="reports" />}
          />
          <Route
            exact
            path="/agreements"
            component={(props) => <Main {...props} view="agreements" />}
          />
          <Route
            exact
            path="/agents"
            component={(props) => <Main {...props} view="agents" />}
          />
          <Route
            exact
            path="/upgrades"
            component={(props) => <Main {...props} view="upgrades" />}
          />
          <Route
            exact
            path="/backoffice"
            component={(props) => <Main {...props} view="back office" />}
          />
          <Route
            exact
            path="/msa_signing"
            component={(props) => <Main {...props} view="msa_signing" />}
          />
          <Route
            exact
            path="/verification/:token_code/"
            component={SignUpVerification}
          />

          <Route exact path="/forgot/" component={Forgot} />
          <Route
            exact
            path="/password_setting/:token_code/"
            component={PasswordSetting}
          />

          {/* END CUSTOMER RESELLER ROUTES */}

          {/* START ADMIN ROUTES */}
          <Route
            exact
            path="/nsaccess"
            component={(props) => <AdminLogin {...props} />}
          />
          <Route
            exact
            path="/admin_dashboard"
            component={(props) => (
              <AdminMain {...props} view="admin_dashboard" />
            )}
          />
          <Route
            exact
            path="/clients_admin"
            component={(props) => <AdminMain {...props} view="clients_admin" />}
          />
          <Route
            exact
            path="/admin_services"
            component={(props) => (
              <AdminMain {...props} view="admin_services" />
            )}
          />
          <Route
            exact
            path="/reports_admin/:api_url/"
            component={(props) => <AdminMain {...props} view="reports_admin" />}
          />
          {/* END ADMIN ROUTES */}

          <Route component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
