import { React } from "react";
import { Component } from "react";

import { COMPANY_NAME } from "../../../config/config";

import $ from "jquery";

export default class AdminDashboard extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* btn modal start*/}
          {/* <Modal /> */}

          {/* btn modal end*/}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Dashboard (COMING SOON)
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
