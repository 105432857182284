import { React } from "react";
import { Component } from "react";
import { Chart } from "react-google-charts";
export default class Analytics extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const options_charts = {
      hAxis: {
        title: "",
      },
      vAxis: {
        title: "",
      },
      series: {
        1: { curveType: "function" },
      },

      selectionMode: "multiple",
    };
    const data_charts = [
      ["x", "OnboardSoft", "Yodel"],
      [0, 0, 0],
      [1, 2, 3],
      [2, 3, 4],
      [3, 4, 5],
      [4, 5, 6],
      [5, 9, 5],
      [6, 11, 3],
      [7, 27, 19],
    ];

    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Analytics</h3>
                    <div className="nk-block-des text-soft">
                      <p>System wise analytics.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-gs">
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 24 Hour
                          Origination Customer Sessions
                        </h6>
                      </div>
                      <div className="">
                        <Chart
                          chartType="LineChart"
                          data={data_charts}
                          options={options_charts}
                          width="500px"
                          height="300px"
                          left="0"
                          top="0"
                          legendToggle
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 24 Hour
                          Termination Customer Sessions
                        </h6>
                      </div>
                      <div className="">
                        <Chart
                          chartType="LineChart"
                          data={data_charts}
                          options={options_charts}
                          width="500px"
                          height="300px"
                          legendToggle
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-gs">
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 6 Month
                          Growth
                        </h6>
                      </div>
                      <div className="">
                        <Chart
                          width={"500px"}
                          height={"300px"}
                          chartType="ComboChart"
                          loader={<div>Loading Chart</div>}
                          data={[
                            ["x", "Growth", "New Sign ups", "Average"],
                            ["2004/05", 2, 2, 10],
                            ["2005/06", 3, 6, 15],
                            ["2006/07", 3, 4, 20],
                            ["2007/08", 4, 6, 25],
                            ["2008/09", 1, 2, 10],
                          ]}
                          options={{
                            title: "",
                            vAxis: { title: "Cups" },
                            hAxis: { title: "Month" },
                            seriesType: "bars",
                            series: { 2: { type: "line" } },
                          }}
                          rootProps={{ "data-testid": "1" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
