import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { addSettingsAdmin } from "../../../actions";
import { COMPANY_NAME } from "../../../config/config";
import { getClients, getReportsData } from "./../../../config/admin_apis";
import $ from "jquery";
import HelperClass from "./../../../config/helperClass";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MutextField from "@material-ui/core/TextField";
const auth = new HelperClass();

class AdminReports extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();
    var datestring_today = new Date().toISOString().slice(0, 10);
    var datestring = new Date().toISOString().slice(0, 10);
    super();
    this.state = {
      reseller_accountno: "925329925329",
      tableData: [],
      tableLoader: true,
      start_date: datestring_today,
      end_date: datestring,
      clientData: [],
      reseller_accountno: "",
      columnsArray: {
        get_daily_orig_stats: {
          title: "Daily Origination Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_daily_did_stats: {
          title: "Daily DID Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "NRC CHARGE" },
            { name: "MRC CHARGE" },
            { name: "E911 CHARGE" },
            { name: "DELETES" },
            { name: "ORDERS" },
          ],
        },
        get_daily_term_stats: {
          title: "Daily Termination Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_daily_sms_stats: {
          title: "Daily SMS Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "INBOUND MESSAGES" },
            { name: "OUTBOUND MESSAGES" },
            { name: "INBOUND ATTEMPTS" },
            { name: "OUTBOUND ATTEMPTS" },
          ],
        },
        get_daily_revenue: {
          title: "Account Daily Revenue",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "DID REVENUE" },
            { name: "VOICE REVENUE" },
            { name: "SMS REVENUE" },
            { name: "BILLED DURATION" },
            { name: "COMPLETED CALLS" },
            { name: "MESSAGES" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "GENERIC CHARGES" },
          ],
        },
        get_aggregate_orig_stats: {
          title: "Account Origination Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_aggregate_term_stats: {
          title: "Account Termination Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_aggregate_sms_stats: {
          title: "Account SMS Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "INBOUND MESSAGES" },
            { name: "OUTBOUND MESSAGES" },
            { name: "INBOUND ATTEMPTS" },
            { name: "OUTBOUND ATTEMPTS" },
          ],
        },
        get_aggregate_did_stats: {
          title: "Account DID Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "DELETES" },
            { name: "ORDERS" },
          ],
        },
        get_aggregate_revenue_stats: {
          title: "Account Revenue",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "DID REVENUE" },
            { name: "VOICE REVENUE" },
            { name: "SMS REVENUE" },
            { name: "BILLED DURATION" },
            { name: "COMPLETED CALLS" },
            { name: "MESSAGES" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "GENERIC CHARGES" },
          ],
        },
      },
    };
  }

  async componentDidMount() {
    await this.clientsView();
    this.setState({ tableLoader: true });
    const reportsData = await getReportsData(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      this.props.props.match.params.api_url,
      this.state.start_date,
      this.state.end_date,
      "all"
    );

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({ tableData: reportsData.data.data, tableLoader: false });
    } else {
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    }
    $("#viewBtn").show();
    $("#btnloader").hide();
  }

  async componentWillReceiveProps(prevProps, nextProps) {
    this.setState({ tableLoader: true });
    await this.clientsView();
    const reportsData = await getReportsData(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      prevProps.props.match.params.api_url,
      this.state.start_date,
      this.state.end_date,
      "all"
    );

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({ tableData: reportsData.data.data, tableLoader: false });
    } else {
      //window.location.replace("/error");
    }
  }

  clientsView = async () => {
    const loadSettings = await getClients(
      auth.getAdminAccount(),
      auth.getAdminToken()
    );
    console.log("loadSettings.data.data: ", loadSettings.data.data);
    if (
      loadSettings.data.status === 403 ||
      loadSettings.data.errors === "authentication missing" ||
      loadSettings.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (loadSettings.data.status === 404) {
    } else if (
      loadSettings.data.status === 200 &&
      loadSettings.data.message === "success"
    ) {
      this.setState({
        clientData: loadSettings.data.data,
        reseller_accountno: loadSettings.data.data[0][1],
      });
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("handleChange " + name + " " + value);
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };

  handleDateChangeEnd = async (date) => {
    await this.handleStatesDynamic("end_date", date.toISOString().slice(0, 10));
    console.log("end_date " + this.state.end_date);
  };

  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
    });
  };

  handleStates = async (start_date, end_date) => {
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
  };

  formSave = async () => {
    let { start_date, end_date } = this.state;
    await this.handleStates(start_date, end_date);
    $("#viewBtn").hide();
    $("#btnloader").show();
    this.setState({ tableLoader: true });
    const reportsData = await getReportsData(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      this.props.props.match.params.api_url,
      this.state.start_date,
      this.state.end_date,
      "all"
    );

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({ tableData: reportsData.data.data, tableLoader: false });
    } else {
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    }
    $("#viewBtn").show();
    $("#btnloader").hide();
    //this.componentDidMount();
  };

  setMarkUp = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    console.log("this.props: ", this.props.props.match);
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Reports</h3>
                  </div>
                </div>
              </div>
              <div className="row g-4">
                <div className="col-lg-4 col-xxl-4">
                  <div className="form-group" style={{ marginTop: "16px" }}>
                    <MutextField
                      id="reseller_accountno"
                      name="reseller_accountno"
                      select
                      label="Select Reseller"
                      value={this.state.reseller_accountno}
                      onChange={this.setMarkUp}
                      SelectProps={{
                        native: true,
                      }}
                      fullWidth
                    >
                      {this.state.clientData.map((clients) => (
                        <option value={clients[1]}>
                          {clients[4]} - {clients[2]}
                        </option>
                      ))}
                    </MutextField>
                  </div>
                </div>
                <div className="col-lg-3 col-xxl-3">
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="start_date"
                        name="start_date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label="Select Start Date"
                        value={this.state.start_date}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-3 col-xxl-3">
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="end_date"
                        name="end_date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label="Select End Date"
                        value={this.state.end_date}
                        onChange={this.handleDateChangeEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-2 col-xxl-2 text-right">
                  <button
                    type="button"
                    id="viewBtn"
                    className="btn btn-lg btn-primary"
                    onClick={() => {
                      this.formSave();
                    }}
                    style={{ marginTop: "20px" }}
                    disabled={this.state.disabled}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    id="btnloader"
                    className="btn btn-lg btn-primary"
                    style={{ marginTop: "20px", display: "none" }}
                    disabled="disabled"
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Filtering...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="nk-block" style={{ marginTop: "15px" }}>
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    {this.state.tableLoader === true ? (
                      tableLoader()
                    ) : (
                      <DataTable
                        columns={
                          this.state.columnsArray[
                            this.props.props.match.params.api_url
                          ].data
                        }
                        tableData={this.state.tableData}
                        title={
                          this.state.columnsArray[
                            this.props.props.match.params.api_url
                          ].title
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addSettingsAdmin: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettingsAdmin: (payload) => dispatch(addSettingsAdmin(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminReports);
