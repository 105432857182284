import { React } from "react";
import { Component } from "react";
import { COMPANY_NAME } from "../../../config/config";

import {
  getServices,
  saveNewService,
  updateService,
  getSingleService,
} from "./../../../config/admin_apis";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";

import TextField from "../../../components/InputFields/TextField";

import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import SettingIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";

import MutextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";

import HomeIcon from "@material-ui/icons/Home";
import EditIcon from "@material-ui/icons/Edit";

const auth = new HelperClass();

export default class AdminServices extends Component {
  constructor() {
    super();

    this.state = {
      apiUrl: "",
      apiKey: "",
      authKey: "",
      useraccountno: "",
      disabled: false,
      errorMessage: "",
      successMessage: "",
      product_title: "",
      service_type: "did",
      mrc: "0.00",
      nrc: "0.00",
      rate: "0.00",
      product_id: "",
      e_product_title: "",
      e_service_type: "did",
      e_mrc: "",
      e_nrc: "",
      e_rate: "",
      sms_in: "0.00",
      sms_out: "0.00",
      e_sms_in: "0.00",
      e_sms_out: "0.00",
      columns: [
        { name: "ID" },
        { name: "Title" },
        { name: "NRC" },
        { name: "MRC" },
        { name: "Rate" },
        { name: "SMS Inbound Rate" },
        { name: "SMS Outbound Rate" },
        { name: "Date Added" },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <a
                    href="#"
                    onClick={() =>
                      this.settingView(
                        tableMeta.rowData[0],
                        tableMeta.rowData[1]
                      )
                    }
                  >
                    <Tooltip title="Edit Service" placement="left">
                      <EditIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </a>
                </div>
              );
            },
          },
        },
      ],
      tableData: [],
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    const clientsResponce = await getServices(
      auth.getAdminAccount(),
      auth.getAdminToken()
    );

    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (clientsResponce.data.status === 404) {
      window.location.replace("/error");
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({ tableData: clientsResponce.data.data });
    } else {
      window.location.replace("/error");
    }
  }

  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  formSubmit = async () => {
    const {
      e_service_type,
      e_product_title,
      e_mrc,
      e_nrc,
      e_rate,
      e_sms_in,
      e_sms_out,
    } = this.state;

    var validated = true;

    if (e_product_title === "") {
      validated = false;
    } else if (e_service_type === "did" || e_service_type === "tfn") {
      if (e_nrc === "") {
        validated = false;
      }
      if (e_mrc === "") {
        validated = false;
      }
      if (e_rate === "") {
        validated = false;
      }
    } else if (e_service_type === "term") {
      if (e_rate === "") {
        validated = false;
      }
    } else if (e_service_type === "sms") {
      if (e_sms_in === "") {
        validated = false;
      }
      if (e_sms_out === "") {
        validated = false;
      }
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await updateService(
        auth.getAdminAccount(),
        auth.getAdminToken(),
        e_product_title,
        e_service_type,
        e_mrc,
        e_nrc,
        e_rate,
        e_sms_in,
        e_sms_out,
        this.state.product_id
      );

      console.log("status: ", saveResponce.data.status);

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Service updated successfully.",
          disabled: false,
        });

        //$("#viewBtn").show();
        $("#btnloader").hide();
        this.componentDidMount();
        setTimeout(function () {
          window.$("#modalForm").modal("hide");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  formAddSubmit = async () => {
    const { service_type, product_title, mrc, nrc, rate, sms_in, sms_out } =
      this.state;

    var validated = true;

    if (product_title === "") {
      validated = false;
    } else if (service_type === "did" || service_type === "tfn") {
      if (nrc === "") {
        validated = false;
      }
      if (mrc === "") {
        validated = false;
      }
      if (rate === "") {
        validated = false;
      }
    } else if (service_type === "term") {
      if (rate === "") {
        validated = false;
      }
    } else if (service_type === "sms") {
      if (sms_in === "") {
        validated = false;
      }
      if (sms_out === "") {
        validated = false;
      }
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await saveNewService(
        auth.getAdminAccount(),
        auth.getAdminToken(),
        product_title,
        service_type,
        mrc,
        nrc,
        rate,
        sms_in,
        sms_out
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Service added successfully.",
          disabled: false,
        });

        //$("#viewBtn").show();
        $("#btnloader").hide();
        this.componentDidMount();
        setTimeout(function () {
          window.$("#modalAddForm").modal("hide");
        }, 3000);
      } else {
        window.location.replace("/error");
      }
    }
  };

  settingView = async (product_id) => {
    //$("#viewBtn").hide();
    $("#btnloader").show();
    this.setState({ product_id: product_id, successMessage: "" });

    const loadSettings = await getSingleService(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      product_id
    );
    console.log("loadSettings: ", loadSettings.data.data[0]);
    if (
      loadSettings.data.status === 403 ||
      loadSettings.data.errors === "authentication missing" ||
      loadSettings.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (loadSettings.data.status === 404) {
    } else if (
      loadSettings.data.status === 200 &&
      loadSettings.data.message === "success"
    ) {
      //$("#viewBtn").show();
      $("#btnloader").hide();
      console.log("epid", loadSettings.data.data[0][1]);
      this.setState({
        e_product_title: loadSettings.data.data[0][1],
        e_service_type: loadSettings.data.data[0][7],
        e_nrc: loadSettings.data.data[0][2],
        e_mrc: loadSettings.data.data[0][3],
        e_rate: loadSettings.data.data[0][4],
        e_sms_in: loadSettings.data.data[0][8],
        e_sms_out: loadSettings.data.data[0][9],
      });
    }
    window.$("#modalForm").modal("show");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };
  render() {
    $("#useraccountno").hide();
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* SETTINGS MODAL START */}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalForm">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Update Service / Product</h5>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* Error Start */}
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <MutextField
                          required
                          id="e_product_title"
                          name="e_product_title"
                          type="text"
                          label="Service Title"
                          value={this.state.e_product_title}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <MutextField
                          id="e_service_type"
                          name="e_service_type"
                          select
                          label="Select Service Type"
                          value={this.state.e_service_type}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          helperText="Please select the service type e.g DID, TollFree or termination."
                          variant="outlined"
                          fullWidth
                        >
                          <option key="did" value="did">
                            DID
                          </option>
                          <option key="tfn" value="tfn">
                            Toll Free
                          </option>
                          <option key="term" value="term">
                            Termination
                          </option>
                          <option key="sms" value="sms">
                            SMS
                          </option>
                        </MutextField>
                      </div>
                    </div>
                    {this.state.e_service_type == "sms" ? (
                      <>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <MutextField
                              required
                              id="e_sms_in"
                              name="e_sms_in"
                              type="text"
                              label="SMS Inbound Rate"
                              defaultValue={this.state.e_sms_in}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <MutextField
                              required
                              id="e_sms_out"
                              name="e_sms_out"
                              type="text"
                              label="SMS Outbound Rate"
                              defaultValue={this.state.e_sms_out}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {this.state.e_service_type !== "term" &&
                    this.state.e_service_type !== "sms" ? (
                      <>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="e_nrc"
                              name="e_nrc"
                              type="text"
                              label="NRC"
                              value={this.state.e_nrc}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="e_mrc"
                              name="e_mrc"
                              label="MRC"
                              value={this.state.e_mrc}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {this.state.e_service_type !== "sms" ? (
                      <>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="e_rate"
                              name="e_rate"
                              label="Rate"
                              value={this.state.e_rate}
                              onChange={this.handleChange}
                              variant="outlined"
                              type="text"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg"
                      style={{
                        color: THEME_TEXT_COLOR,
                        float: "right",
                        marginTop: "15px",
                      }}
                      disabled={this.state.disabled}
                      value="Save"
                      onClick={() => this.formSubmit()}
                    />
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL EDIT PROFILE */}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalAddForm">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add Service / Product</h5>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* Error Start */}
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <MutextField
                          required
                          id="product_title"
                          name="product_title"
                          type="text"
                          label="Service Title"
                          defaultValue={this.state.product_title}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <MutextField
                          id="service_type"
                          name="service_type"
                          select
                          label="Select Service Type"
                          defaultValue={this.state.service_type}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          helperText="Please select the service type e.g DID, TollFree or termination."
                          variant="outlined"
                          fullWidth
                        >
                          <option key="did" value="did">
                            DID
                          </option>
                          <option key="tfn" value="tfn">
                            Toll Free
                          </option>
                          <option key="term" value="term">
                            Termination
                          </option>
                          <option key="sms" value="sms">
                            SMS
                          </option>
                        </MutextField>
                      </div>
                    </div>
                    {this.state.service_type == "sms" ? (
                      <>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <MutextField
                              required
                              id="sms_in"
                              name="sms_in"
                              type="text"
                              label="SMS Inbound Rate"
                              defaultValue={this.state.sms_in}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <MutextField
                              required
                              id="sms_out"
                              name="sms_out"
                              type="text"
                              label="SMS Outbound Rate"
                              defaultValue={this.state.sms_out}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {this.state.service_type !== "term" &&
                    this.state.service_type !== "sms" ? (
                      <>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="nrc"
                              name="nrc"
                              type="text"
                              label="NRC"
                              defaultValue={this.state.nrc}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="mrc"
                              name="mrc"
                              label="MRC"
                              defaultValue={this.state.mrc}
                              onChange={this.handleChange}
                              variant="outlined"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {this.state.service_type !== "sms" ? (
                      <>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <MutextField
                              required
                              id="rate"
                              name="rate"
                              label="Rate"
                              defaultValue={this.state.rate}
                              onChange={this.handleChange}
                              variant="outlined"
                              type="text"
                              onKeyPress={() => {
                                this.checkNumber();
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg"
                      style={{
                        color: THEME_TEXT_COLOR,
                        float: "right",
                        marginTop: "15px",
                      }}
                      disabled={this.state.disabled}
                      value="Save"
                      onClick={() => this.formAddSubmit()}
                    />
                  </div>

                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL ADD SERVICE */}
          {/* SETTINGS MODAL END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Services / Products
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              href=""
                              data-toggle="modal"
                              data-target="#modalAddForm"
                              style={{
                                color: "#fff",
                              }}
                              className="btn btn-primary"
                            >
                              <em class="icon ni ni-plus-round"></em>
                              <span>Add New Service</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START TABLE HEAD */}
                      <DataTable
                        columns={this.state.columns}
                        tableData={this.state.tableData}
                      />
                      {/* END TABLE HEAD */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
