import react, { Component } from "react";
import ContentLoader from "react-content-loader";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

import { Redirect } from "react-router-dom";

import AdminFooter from "./AdminFooter";
import AdminHeader from "./AdminHeader";
import AdminLeftSideBar from "./AdminLeftSideBar";
import AdminDashboard from "../../Pages/Admin/AdminDashboard";

import ErrorPage from "../Errors/404";
import AdminClients from "../../Pages/Admin/AdminClients";
import AdminServices from "../../Pages/Admin/AdminServices";
import AdminReports from "../../Pages/Admin/AdminReports";
import Services from "../../Pages/Reseller/Services";

import DashboardLoader from "../ContentLoaders/dashboard_loader";
import { leftMenu, validateRights } from "../../config/admin_apis";
import { LOGO, THEME_COLOR, THEME_TEXT_COLOR } from "../../config/config";
import { REPLACECONTENT, ValidateEmail, normalize } from "../../config/utility";
import HelperClass from "./../../config/helperClass";

import TextField from "../InputFields/TextField";

const auth = new HelperClass();

export default class Admin extends Component {
  constructor(props) {
    super(props);

    if (auth.isAdminLogin() === false) {
      auth.adminlogout();
    }

    this.state = {
      view: this.props.view,
      activePage: "",
      Agreement_Content: "",
      title_agreement: "",
      bgColor: "#f5f6fa",
      vendor_signor_signature_file: "",
      published_date: "",
      defaultLoader: true,
      errorCompName: "",
      comp_name: "",
      errorName: "",
      name: "",
      errorTitle: "",
      title: "",
      errorEmail: "",
      email: "",
      errorPhone: "",
      phone: "",
      errorPass: "",
      password: "",
      errorMessage: "",
      disabled: "",
      successMessage: "",
      leftMenuArr: [],
    };
  }

  getLeftMenu = async () => {
    let res = await leftMenu(auth.getAdminAccount(), auth.getAdminToken());

    if (
      res.data.status === 200 &&
      res.data.message === "success" &&
      res.data.data.length > 0
    ) {
      this.setState({ leftMenuArr: res.data.data });
    }
  };

  async componentDidMount() {
    this.setState({
      comp_name: auth.getCompName(),
      name: auth.getName(),
      email: auth.getEmail(),
      phone: auth.getPhone(),
      title: auth.getTitle(),
    });
    await this.getLeftMenu();

    var getUri = window.location.pathname.split("/");
    let rightsIdentify = await validateRights(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      getUri[1]
    );
    //console.log("rightsIdentify: ", rightsIdentify);
    if (
      rightsIdentify.data.status === 200 &&
      rightsIdentify.data.message === "success"
    ) {
    } else {
      auth.adminlogout();
      window.location.replace("error");
    }
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  pageView = () => {
    if (this.state.view === "admin_dashboard") {
      return <AdminDashboard props={this.props} />;
    } else if (this.state.view === "clients_admin") {
      return <AdminClients props={this.props} />;
    } else if (this.state.view === "admin_services") {
      return <AdminServices props={this.props} />;
    } else if (this.state.view === "reports_admin") {
      return <AdminReports props={this.props} />;
    } else if (this.state.view === "services") {
      return <Services props={this.props} />;
    } else {
      return <ErrorPage props={this.props} />;
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  pageChange = (data) => {
    this.props.history.push("/" + data);
  };

  profileView = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    window.$("#modalForm").modal("show");
  };

  render() {
    return (
      <div className="nk-body bg-lighter npc-general has-sidebar ">
        <div className="nk-app-root">
          <div className="nk-main">
            <div>
              <AdminLeftSideBar
                {...this.props}
                menuArr={this.state.leftMenuArr}
              />
            </div>
            <div className="nk-wrap ">
              {/* {this.state.defaultLoader === true ? <DashboardLoader /> : null} */}
              <AdminHeader
                modalOpen={() => {
                  this.profileView();
                }}
              />
              {this.pageView()}

              <AdminFooter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
